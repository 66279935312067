import { merge as _merge } from 'lodash-es';
import * as helpers from '../helpers';

const TYPE = 'close-button';
const SELECTOR = '#om-campaign-0 .om-popup-close';

const _generatePosition = (position, template, mobile = false) => {
  const result = {};
  for (const key in position) {
    if (!['top', 'right', 'bottom', 'left'].includes(key)) return;
    const generator = helpers[key];
    if (generator) {
      _merge(result, helpers[key]('', TYPE, position[key], mobile, template, SELECTOR));
    }
  }
  return result;
};

const _setStyles = (toObj, style, template, mobile = false) => {
  for (const key in style) {
    if (key === 'background') {
      const bg = style[key];
      if (typeof bg === 'object') {
        if ([undefined, null].includes(bg.color)) continue;
        _merge(toObj, helpers.background(TYPE, TYPE, bg, mobile, template, SELECTOR));
      } else {
        if (!template || !template.palette) continue;
        const css = helpers._color(bg, template.palette);
        _merge(toObj, {
          [SELECTOR]: { background: { v: css } },
        });
      }
      continue;
    }

    const generator = helpers[key];
    if (!generator) continue;
    const value = style[key];

    if (value === null && mobile) continue;

    _merge(toObj, generator(TYPE, TYPE, value, mobile, template, SELECTOR));
  }
};

export default (style, template) => {
  const _desktop = {};
  const _mobile = {};

  _setStyles(_desktop, style, template);
  _setStyles(_desktop, style.desktop, template);
  _setStyles(_mobile, style.mobile, template, true);

  _merge(_desktop, _generatePosition(template.style.closeButton.desktop, template));
  _merge(_mobile, _generatePosition(template.style.closeButton.mobile, template, true));

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
